<template>
  <BaseLayout :custom-class="{coming_soon: true}">
    <SubHeader :custom-class="{coming_soon_sub_header: true}"></SubHeader>
    <div id="bg-container" style="text-align: center;">
      <h2 style="text-align: center; color: #fff;">
        Mane Street Market is now offering simple access to an equine financing option.
        <br><br>
        Finance the purchase of a horse, horse trailer, saddle and all things equine with LightStream for a quick way to get the funds you need.
        <br><br>
        LightStream offers flexible terms, no fees, and a hassle-free process for good-credit customers to get the financing they need to achieve their equestrian goals.
      </h2>
      <br>
      <p style="text-align: center; font-style: italic; color: #fff;">*Privacy disclaimer: Mane Street Market does not have access to your personal/financial information. All loans are subject to approval by LightStream.</p>
      <a class="cta-btn" href="https://www.lightstream.com/?fact=14138&amp;irad=88408&amp;irmp=3965510">Get Started</a>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';

export default {
  components: {BaseLayout, SubHeader},
  mounted() {
    document.title = 'Equine Financing - Mane Street Market';
    window.scrollTo(0, 0);
  }
}
</script>

<style lang="scss">
.coming_soon {
  padding-bottom: 0 !important;
}

.coming_soon_sub_header {
  margin-bottom: 0 !important;
}
</style>

<style lang="scss" scoped>
#bg-container {
  background: linear-gradient(270deg, #15249D99 0%, #0011A08F 44%, #04117B 100%, #132093 100%), url("../assets/img/comingsoonpage.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 140px 120px 140px;
}

@media screen and (max-width: 768px) {
  #bg-container {
    padding: 40px 20px 40px;

    h2 {
      font-size: 1.5rem;
    }
  }
}

.cta-btn {
  font-size: 24px;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 5px;
  background: #fff;
  margin-top: 16px;
  display: inline-block;

  &:hover {
    text-decoration: none;
  }
}
</style>